export const recordStatusConfig = {
  active: { value: 'active', label: '進行中', tagType: 'action' },
  paused: { value: 'paused', label: '暫停中', tagType: 'warning' },
  ended: { value: 'ended', label: '已結束', tagType: 'info' },
  terminated: { value: 'terminated', label: '已終止', tagType: 'info' },
}

export const recordOriginConfig = {
  admin: { value: 'admin', label: '後台新增' },
  memberStore: { value: 'memberStore', label: '會員商城' },
  subscriptionOrder: { value: 'subscriptionOrder', label: '訂閱訂單' },
}

export const periodicBenefitTemplateTypeConfig = {
  standard: { value: 'standard', label: '非訂閱商品' },
  subscription: { value: 'subscription', label: '訂閱商品' },
}

export const periodicBenefitCycleTypeConfig = {
  pending: { value: 'pending', label: '處理中' },
  success: { value: 'success', label: '發放成功' },
  failed: { value: 'failed', label: '發放失敗' },
  notMathcCondition: { value: 'notMathcCondition', label: '條件不符合' },
}

export const periodicBenefitRecordLogTypeConfig = {
  active: { value: 'active', label: '權益恢復'},
  paused: { value: 'paused', label: '權益暫停'},
}
