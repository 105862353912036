<template>
  <div class="change-log">
    <p class="font-medium mb-[24px]">會員權益發放紀錄</p>
    <el-steps
      v-if="displayData.length"
      direction="vertical"
      :active="displayData.length"
    >
      <el-step
        v-for="log in displayData"
        :key="log.id"
      >
        <template slot="description">
          <div class="flex flex-col gap-[8px]" style="padding-bottom: 20px">
            <div class="flex flex-col gap-[8px]">
              <p class="dateTime">{{ log.updatedAt }}</p>
              <div v-if="log.status" class="flex items-center gap-[12px]">
                <p class="label">發放結果</p>
                <p class="value">{{ log.status }}</p>
              </div>
              <div v-if="log.failReason" class="flex items-center gap-[12px]">
                <p class="label">原因</p>
                <p class="value">{{ log.failReason }}</p>
              </div>
              <div v-if="log.edit" class="flex items-center gap-[12px]">
                <p class="label">操作</p>
                <p class="value">{{ log.edit }}</p>
              </div>
            </div>
          </div>
        </template>
      </el-step>
    </el-steps>
    <EmptyBlock v-else />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import { formatDate } from '@/utils/date'
import { get, map, filter } from 'lodash'
import { periodicBenefitCycleTypeConfig, periodicBenefitRecordLogTypeConfig } from '@/config/periodicBenefit'

export default defineComponent({
  name: 'PeriodicBenefitUsageLogBlock',
  props: {
    data: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const displayData = computed(() => {
      const data = get(props.data, 'timelines', [])
      const periodicBenefitRecordLog = filter(data, (item) => get(item, 'periodicBenefitRecordLog'))
      return map(periodicBenefitRecordLog, (item) => {
        const { periodicBenefitCycle, periodicBenefitRecordLog } = item
        if (periodicBenefitCycle) {
          return {
            updatedAt: formatDate(get(periodicBenefitCycle, 'awardAt')),
            status: selectStatus(get(periodicBenefitCycle, 'status'), 'label'),
            failReason: get(periodicBenefitCycle, 'failReason'),
          }
        } else if (periodicBenefitRecordLog) {
          return {
            updatedAt: formatDate(get(periodicBenefitRecordLog, 'updatedAt')),
            edit: editStatus(get(periodicBenefitRecordLog, 'content.newValue'), 'label'),
          }
        }
      })
    })
    const selectStatus = (status, attr) => {
      return get(periodicBenefitCycleTypeConfig[status], attr, '')
    }
    const editStatus = (status, attr) => {
      return get(periodicBenefitRecordLogTypeConfig[status], attr, '')
    }
    return { displayData, get }
  },
})
</script>

<style lang="postcss" scoped>
.dateTime {
  @apply text-gray-60 text-sub;
}

::v-deep .el-step__description {
  @apply pr-0 top-[-4px] relative;
}

::v-deep .el-step__icon.is-text {
  @apply top-[-4px] h-[15px] w-[15px] ml-[5px] bg-primary-100;
}

::v-deep .el-step__icon-inner {
  @apply hidden;
}

.label {
  @apply text-sub font-medium text-gray-100;
}
.value {
  @apply text-sub text-gray-60;
}
</style>
